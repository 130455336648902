export default {
    global:{
        title:"华夏儿女 · 幸福安康",
        male:"男",
        female:"女",
        correct:"是",
        deny:"否",
    },
    tab:{
        home:"首页",
        enterprise:"企业",
        product:"产品",
        mall:"商城",
        service:"服务",
        securityCheck:"防伪查询",
        contact:"联系方式",
        friendlyLinks:"友情链接",
        huaKangLiquorOfficialMall:"华康酒官方商城",
        enterNumber:"输入防伪号",

    },
    home:{
        companyProfile:"企业简介",
        corporateCulture:"企业文化",
        qualificationsHonors:"资质荣誉",
        corporateCultureInfo:"华康酒，产自中国酒都--茅台镇，地处赤水河畔，享有得天独厚的天然条件，采用传统的酿造工艺，经过九次蒸煮、八次发酵、七次取酒，三年以上岁月沉淀而成。具有酱香突出、优雅细腻、酒体醇厚、回味悠长、空杯留香等特点。",
        brewing:"窑藏精酿",
        sauceAroma:"一种精酿 酿造酱香",
        Detail:"查看详情",
        Consultation:"咨询热线",
        ConsultationInfo:"依托金盆地清代老窖泥，窖香舒适的口感佳酿",
        material:"原 料",
        water:"水",
        sorghum:"高粱",
        wheat:"小麦",
        flavor:"香 型",
        maotaiFlavor:"酱香型",
        netContent:"净含量",
        alcoholContent:"酒精度",
        huakangLiquor:"华康酒",
    },
    service:{
        recruit:{
            recruit:"人才招聘",
            job:"招聘职位",
            workLocation:"工作地点",
            recruitingNumbers:"招聘人数",
            operate:"操作",
            share:"分享",
            deliver:"投递",
            Resume:"个人简历",
            rName:"姓名",
            Age:"年龄",
            rGender:"性别",
            rNation:"民族",
            PositionApplied:"应聘职位",
            SalaryExpectation:"期望薪资",
            GraduationSchool:"毕业学校",
            OnCampusStudents:"在校学生",
            rContact:"联系方式",
            rMailbox:"邮箱",
            rMailAddress:"通讯地址",
            PersonalSkills:"个人技能",
            WorkExperience:"工作经历",
            SelfEvaluation:"自我评价",
            rSubmit:"提交",
            male:"男",
            female:"女"
        },
        us:{
            contactUs:"联系我们",
            huakangIndustry:"华康酒业有限公司",
            serviceHotline:"服务热线",
            companyEmail:"公司邮箱",
            companyAddress:"公司地址",
            addressInfo:"郑州市郑东新区农业东路宝龙广场33号",
        },
        advice:{
            advice:"咨询建议",
            name:"您的姓名",
            enterName:"请输入姓名",
            gender:"您的性別",
            gentleman:"先生",
            lady:"女士",
            telephone:"联系电话",
            PleaseNumber:"请输入电话",
            Email:"您的邮箱",
            enterEmail:"请输入邮箱",
            messageContent:"留言内容",
            enterMessage:"请输入留言内容",
            confirmSubmission:"确认提交"
        }
    },
    other:{
        hkInfo1:"华康酒业专注研发以“华夏儿女幸福安康”为主线的文化品类收藏款白酒。 民间有句谚语“无酒不成席”，而酒是人类生活中的主要饮品之一。",
        hkInfo2:"源远流长的中国酒文化在推动传统历史文化发展方面发挥着重要的作用，而文化创意产品作为文宣区域文化，民族文化的典型产品，在经济新常态下实现对酒文化的创意产品设计，有着文宣和发展的重要作用。 同时华康酒业研发文创产品也是发展的必要途径之一。",
        EnterHuakang:"走进华康",
        CorporateHonor:"企业荣誉",
        organization:"2023年被评为先进单位",
        red:"华康酒红色经典",
        orange:"华康酒橙色经典",
        yellow:"华康酒黄色经典",
        green:"华康酒绿色经典",
        cyan:"华康酒青色经典",
        blue:"华康酒蓝色经典",
        purple:"华康酒紫色经典",
        golden:"华康酒金色经典",
        black:"华康酒黑色经典",
        hkProduct:"华康产品",

    },
    h5Other:{
        details:"详情",
        YuanMonth:"元/月",
        hname:"请输入您的姓名",
        hgender:"请选择您的性别",
        information:"请输入您的联系方式",
        address:"请输入您的邮箱",
        message:"请输入您的留言",
        mCompany:"公司  Company",
        mAddress:"地址  Address",
        mContact:"联系方式  Contact",
        mDeliver:"立即投递",
    },
    securityCheck: {
        counterfeiting: "请输入防伪号",
        Confirm: "确认查询",
        info1: "确认查询防伪查询提示：为保证消费者查询产品真伪客观公正，请自助查询，请勿通过人工服务查询",
        info2: "防伪码查询正确——您所购买的产品为经过华康控股防伪查询中心的产品，请您放心使用此产品",
        info3: "防伪码查询错误——防伪码查询不存在，您所购买的产品未经华康控股防伪查询中心，不存在，谨防假冒",
        info4: "防伪码查询重复——同一防伪码被多次查询，请确认首次防伪码查询是否本人，非本人，可能为假冒产品",
    },
    recruit:{
        Graphic:"销售",
        CityProvince:"河南省·郑州市",
        Recruiting:"在招1人",
        fullTime:"全职",
        requirements:"职位要求",
        responsibility:"岗位职责",
        requirement:"任职要求",
        zhenzhou:"郑州",
        linfo1:"1.熟悉市场情况，具有独立开拓市场能力。",
        linfo2:"2.表达能力强，反应敏捷，热爱学习。",
        linfo3:"3.完成业务相关事宜，积极开发新的销售渠道。",
        linfo4:"1.市场营销相关专业专科以上学历，有一年以上同职位工作经历优先。",
        linfo5:"2.具有良好的服务意识，有耐心，责任心、性格乐观大方，不怕吃苦，能够承担一定的工作压力。",
        linfo6:"3.熟悉使用电脑和通讯软件，熟练操作办公软件，有较强的学习能力。"
    }
}
