import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "animate.css/animate.min.css";
import 'wowjs/css/libs/animate.css'
import '@@/font/font.css'
import 'vant/lib/index.css';
import { createPinia } from 'pinia'
import { ActionSheet } from 'vant';
import i18n from "@/lang";







const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$t = i18n.global.t

/**只在中文显示*/
app.directive("langShow",{
    mounted(el:HTMLElement){
        const lang = localStorage.getItem("lang");
        console.log(el)
        if(lang==="cn"){
            el.style.display="line-block"
        }else {
            el.style.display="none"
        }
    }
})
app.use(router).use(ElementPlus).use(createPinia()).use(ActionSheet).use(i18n).mount('#app')
