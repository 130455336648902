import { defineStore } from 'pinia'
import {ref} from "vue";

export const useTab = defineStore('tab', ()=>{
    const selectTab = ref(1);
    const tabChange=(index:number)=>{
        selectTab.value=index;
        sessionStorage.setItem("tab",String(index))
    }
  return {selectTab,tabChange}
})
