import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const p_routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Home,
    redirect:"/home",
    children:[
      {
        path:"/home",
        name:"home",
        component:()=>import("../views/content/Application.vue")
      },
      {
        path:"/security",
        name:"security",
        component:()=>import("../views/content/AntiCounterfeiting.vue")
      },
      {
        path:"/service",
        name:"service",
        component:()=>import("../views/service/index.vue"),
        redirect:"/recruit",
        children:[
          {
          path: "/contactUs",
          name: "contactUs",
          component: () => import("../views/service/contactUs.vue")
          },
          {
            path: "/recruit",
            name: "recruit",
            component: () => import("../views/service/recruit.vue")
          },
          {
            path: "/advice",
            name: "advice",
            component: () => import("../views/service/advice.vue")
          },
          {
            path: "/biographicalNotes",
            name: "biographicalNotes",
            component: () => import("../views/service/biographicalNotes.vue")
          },
        ]
      },
      {
        path:"/news",
        name:"news",
        component:()=>import("../views/news/index.vue")
      },
      {
        path:"/details",
        name:"details",
        component:()=>import("../views/news/details.vue")
      },
      {
        path:"/product",
        name:"product",
        component:()=>import("../views/product/index.vue")
      },
      {
        path:"/enterprise",
        name:"enterprise",
        component:()=>import("../views/enterprise/index.vue")
      },

    ]
  },
]
const m_routes:Array<RouteRecordRaw>=[
  {
    path: '/',
    name: 'index',
    component: ()=>import("../h5views/Home.vue"),
    redirect:"/home",
    children:[
      {
        path:"/home",
        name:"home",
        component:()=>import("../h5views/content/Application.vue")
      },
      {
        path:"/security",
        name:"security",
        component:()=>import("../h5views/content/AntiCounterfeiting.vue")
      },
      {
        path:"/enterprise",
        name:"enterprise",
        component:()=>import("../h5views/enterprise/index.vue")
      },
      {
        path:"/product",
        name:"product",
        component:()=>import("../h5views/product/index.vue")
      },
      {
        path:"/news",
        name:"news",
        component:()=>import("../h5views/news/index.vue")
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("../h5views/service/contactUs.vue")
      },
      {
        path: "/recruit",
        name: "recruit",
        component: () => import("../h5views/service/recruit.vue")
      },
      {
        path: "/advice",
        name: "advice",
        component: () => import("../h5views/service/advice.vue")
      },
      {
        path: "/biographicalNotes",
        name: "biographicalNotes",
        component: () => import("../h5views/service/biographicalNotes.vue")
      },
    ]
  }

]

let routes: Array<RouteRecordRaw> = []
if(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
  console.log("m_routes")
  routes=m_routes;
}else {
  console.log("p_routes")
  routes=p_routes;
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to,from,savedPosition){
    return {top:0,behavior:'smooth'}
  }
})

export default router
