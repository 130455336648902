export default {
    global: {
        title: "Anak-anak Huaxia · Kebahagiaan dan Kesehatan",
        male: "Laki-laki",
        female: "Perempuan",
        correct: "Ya",
        deny: "Tidak",
    },
    tab: {
        home: "Beranda",
        enterprise: "Perusahaan",
        product: "Produk",
        mall: "mall",
        service: "Layanan",
        securityCheck: "Periksa",
        contact: "Informasi kontak",
        friendlyLinks: "Tautan yang Ramah",
        huaKangLiquorOfficialMall: "Toko Resmi Hua Kang Liquor",
        enterNumber: "Masukkan Nomor Keaslian",
    },
    home: {
        companyProfile: "Profil",
        corporateCulture: "Budaya",
        qualificationsHonors: "Kualifikasi",
        corporateCultureInfo: "Hua Kang Liquor diproduksi di Maotai Town, ibukota minuman keras Tiongkok, terletak di tepi Sungai Chishui. Itu menikmati kondisi alam yang unggul dan mengadopsi teknik pembuatan tradisional. Melalui sembilan kali perebusan, kali fermentasi, dan tujuh kali ekstraksi minuman keras, minuman ini dibuat setelah mengendap selama lebih dari tiga tahun. Ini memiliki aroma saus yang menonjol, keanggunan, tekstur lembut, aftertaste yang tahan lama, dan aroma yang tersisa di gelas kosong.",
        brewing: "Kiln Collection Brewing",
        sauceAroma: "Seorang jenis aroma saus buatan kapal",
        Detail: "Lihat Rincian",
        Consultation: "Garis panas konsultasi",
        ConsultationInfo: "Bergantung pada lumpur penampungan zaman dinasti Qing di Cekungan Jin-Pen, aroma dari tempat penyimpan memberikan cita rasa yang nyaman",
        material: "Bahan Baku",
        water: "Air",
        sorghum: "Sorgum",
        wheat: "Gandum",
        flavor: "Jenis Aroma",
        maotaiFlavor: "Aroma Saus",
        netContent: "Konten Bersih",
        alcoholContent: "Kadar Alkohol",
        huakangLiquor: "Hua Kang Liquor",
    },
    service: {
        recruit: {
            recruit: "Rekrutmen Talenta",
            job: "Posisi Pekerjaan",
            workLocation: "Lokasi Kerja",
            recruitingNumbers: "Jumlah Rekrutan",
            operate: "Operasikan",
            share: "Bagikan",
            deliver: "Kirim",
            Resume: "Resume",
            rName: "Nama",
            Age:"Age",
            rGender: "Jenis Kelamin",
            rNation: "Kebangsaan",
            PositionApplied: "Posisi yang Dilamar",
            SalaryExpectation: "Ekspektasi Gaji",
            GraduationSchool: "Sekolah Lulusan",
            OnCampusStudents: "Mahasiswa",
            rContact: "Informasi kontak",
            rMailbox: "kotak surat",
            rMailAddress: "Alamat Mel",
            PersonalSkills: "Keahlian Pribadi",
            WorkExperience: "Pengalaman Kerja",
            SelfEvaluation: "Penilaian Diri",
            rSubmit: "Kirim",
            male:"laki-laki",
            female:"wanita"
        },
        us: {
            contactUs: "Hubungi Kami",
            huakangIndustry: "Industri Hua Kang Liquor Co., Ltd.",
            serviceHotline: "Jalur panas layanan",
            companyEmail: "Surel Perusahaan",
            companyAddress: "Alamat Perusahaan",
            addressInfo: "No. 33, Baolong Plaza, Agricultural East Road, Zhengdong New District, Kota Zhengzhou",
        },
        advice: {
            advice: "Konsultasi & Saran",
            name: "Nama Anda",
            enterName: "Masukkan nama Anda",
            gender: "Jenis Kelamin Anda",
            gentleman: "Tuan",
            lady: "Nyonya",
            telephone: "Nomor Kontak",
            PleaseNumber: "Masukkan nomor telepon Anda",
            Email: "Surel Anda",
            enterEmail: "Masukkan surel Anda",
            messageContent: "Konten Pesan",
            enterMessage: "Masukkan pesan Anda",
            confirmSubmission: "Konfirmasi Pengiriman",
        },
    },
    other:{
        hkInfo1:"Huakang Liquor Industry focuses on the research and development of cultural category collection Baijiu with the theme of \"happiness and well-being of Chinese children\".  dan anggur adalah salah satu minuman utama dalam kehidupan manusia.",
        hkInfo2:"Minuman alkohol di Cina dengan sejarah panjang bermain peran penting dalam mempromosikan pembangunan sejarah tradisional dan budaya. Sebagai produk tipis budaya regional dan budaya nasional, produk budaya kreatif bermain peran penting dalam propaganda budaya dan pembangunan dengan menyadari desain produk kreatif budaya anggur di bawah budaya baru normal ekonomi.",
        EnterHuakang:"Masuk Huakang",
        CorporateHonor:"Kehormatan Perusahaan",
        organization:"Organisasi contoh tahun 2023",
        red:"Huakang Wine Red Classic",
        orange:"Huakang Wine orange Classic",
        yellow:"Huakang Wine yellow Classic",
        green:"Huakang Wine green Classic",
        cyan:"Huakang Wine cyan Classic",
        blue:"Huakang Wine blue Classic",
        purple:"Huakang Wine purple Classic",
        golden:"Huakang Wine golden Classic",
        black:"Huakang Wine black Classic",
        hkProduct:"Produk Huakang",
    },
    h5Other:{
        details:"rincian",
        YuanMonth:"Yuan/bulan",
        hname:"Silakan masukkan nama Anda",
        hgender:"Silakan pilih jenis Anda",
        information:"Silakan masukkan informasi kontak Anda",
        address:"Silakan masukkan alamat email Anda",
        message:"Silakan masukkan pesan Anda",
        mCompany:"perusahaan",
        mAddress:"alamat",
        mContact:"Informasi kontak",
        mDeliver:"Pengiriman",
    },
    securityCheck: {
        counterfeiting: "Silakan masukkan nomor anti-palsu",
        Confirm: "Konfirmasi",
        info1: "Confirmation query anti-counterfeiting query prompt: To ensure that consumers can objectively and fairly inquire about the authenticity of products, please self check and do not use manual services to check",
        info2: "Pertanyaan kode anti-palsu benar - produk yang Anda beli adalah produk yang telah melewati pusat pertanyaan anti-palsu Huakang Holdings. Silakan pastikan untuk menggunakan produk ini",
        info3: "Galat dalam pertanyaan kode anti-palsu - pertanyaan kode anti-palsu tidak ada. Produk yang Anda beli belum disetujui oleh pusat pertanyaan anti-palsu Huakang Holdings, dan tidak ada. Hati-hati dari palsu",
        info4: "Duplikasi pertanyaan kode anti-palsu - kode anti-palsu yang sama telah ditanyai beberapa kali. Silakan konfirmasi apakah pertanyaan kode anti-palsu pertama dilakukan sendiri, bukan diriku sendiri, dan mungkin adalah produk palsu",
    },
    recruit:{
        Graphic:"penjualan",
        CityProvince:"ZhengzhouHenan",
        requirements:"Keperlukan tugas",
        Recruiting:"Menangkap 1 orang",
        fullTime:"Waktu Penuh",
        responsibility:"tanggung jawab",
        requirement:"perlukan",
        zhenzhou:"zhenzhou",
        linfo1:"1. Berkenal dengan kondisi pasar dan memiliki kemampuan untuk mengeksplorasi pasar secara independen.",
        linfo2:"2. Kemampuan ekspresi kuat, reaksi cepat, dan cinta untuk belajar.",
        linfo3:"3. Selesaikan urusan bisnis dan aktif mengembangkan saluran penjualan baru.",
        linfo4:"1. Digelar kuliah atau lebih dalam bidang berkaitan pemasaran, dengan setidaknya satu tahun pengalaman kerja di posisi yang sama yang disukai.",
        linfo5:"2. Memiliki perasaan layanan yang baik, pasien, bertanggung jawab, optimistis dan personalitas yang murah hati, tidak takut kesulitan, mampu menahan tekanan pekerjaan tertentu.",
        linfo6:"3. Berkenal dengan menggunakan komputer dan perangkat lunak komunikasi, ahli dalam operasi perangkat lunak kantor, dengan kemampuan belajar yang kuat."
    }
}
