import { defineStore} from "pinia";
import {ref} from "vue";

export type LangType = "cn" | "en" | "bi"

export const useLangType = defineStore("langType",()=>{
    const langType = ref<LangType>("cn")

    const changeLangType =(lang:LangType) =>{
        langType.value=lang
        localStorage.setItem("lang",String(langType.value))
    }
    return {langType, changeLangType }
})
