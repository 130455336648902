import {createI18n} from "vue-i18n";
import EN from "./en";
import CN from "./cn";
import BI from "./bi"
const i18n = createI18n({
    locale:'cn',
    messages:{
        cn:CN, //中文
        en:EN, //英文
        bi:BI
    },
    legacy:false,
    globalInjection:true
})


export default i18n;
