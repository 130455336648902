import useCurrentInstance from "@/tools/useCurrentInstance";
import {useI18n} from "vue-i18n";
import {useLangType} from "@/store/langType";
import i18n from "@/lang";


interface Lang{
    home:string,
    enterprise:string,
    product:string,
    mall:string,
    service:string,
    securityCheck:string,
    contact:string,
    friendlyLinks:string,
    huaKangLiquorOfficialMall:string,
    enterNumber:string,
    title:string,
    male:string,
    female:string,
    correct:string,
    deny:string,
    companyProfile:string,
    corporateCulture:string,
    qualificationsHonors:string,
    corporateCultureInfo:string,
    brewing:string,
    sauceAroma:string,
    Detail:string,
    Consultation:string,
    ConsultationInfo:string,
    material:string,
    water:string,
    sorghum:string,
    wheat:string,
    flavor:string,
    maotaiFlavor:string,
    netContent:string,
    alcoholContent:string,
    huakangLiquor:string,
    recruit:string,
    job:string,
    workLocation:string,
    recruitingNumbers:string,
    operate:string,
    share:string,
    deliver:string,
    Resume:string,
    rName:string,
    rGender:string,
    rNation:string,
    PositionApplied:string,
    SalaryExpectation:string,
    GraduationSchool:string,
    OnCampusStudents:string,
    rContact:string,
    rMailbox:string,
    rMailAddress:string,
    PersonalSkills:string,
    WorkExperience:string,
    SelfEvaluation:string,
    rSubmit:string,
    contactUs:string,
    huakangIndustry:string,
    serviceHotline:string,
    companyEmail:string,
    companyAddress:string,
    addressInfo:string,
    advice:string,
    name:string,
    enterName:string,
    gender:string,
    gentleman:string,
    lady:string,
    telephone:string,
    PleaseNumber:string,
    Email:string,
    enterEmail:string,
    messageContent:string,
    enterMessage:string,
    confirmSubmission:string,
    hkInfo1:string,
    hkInfo2:string,
    EnterHuakang:string,
    CorporateHonor:string,
    organization:string,
    red:string,
    orange:string,
    yellow:string,
    green:string,
    cyan:string,
    blue:string,
    purple:string,
    golden:string,
    black:string,
    hkProduct:string,
    Age:string,
    details:string,
    YuanMonth:string,
    hname:string,
    hgender:string,
    information:string,
    address:string,
    message:string,
    mCompany:string,
    mAddress:string,
    mContact:string,
    mDeliver:string,
    counterfeiting: string,
    Confirm: string,
    info1: string,
    info2: string,
    info3: string,
    info4: string,
    Graphic:string,
    CityProvince:string,
    Recruiting:string,
    fullTime:string,
    responsibility:string,
    requirement:string,
    zhenzhou:string,
    linfo1:string,
    linfo2:string,
    linfo3:string,
    linfo4:string,
    linfo5:string,
    linfo6:string,
    requirements:string,

}



export default  ():Lang => {
    const {proxy} = useCurrentInstance();
    const langType = useLangType()
    const t = proxy.$t;
    const {locale} =useI18n();
    locale.value=langType.langType
    return{
        home:t('tab.home'),
        enterprise:t('tab.enterprise'),
        product:t('tab.product'),
        mall:t('tab.mall'),
        service:t('tab.service'),
        securityCheck:t('tab.securityCheck'),
        contact:t('tab.contact'),
        friendlyLinks:t('tab.friendlyLinks'),
        huaKangLiquorOfficialMall:t('tab.huaKangLiquorOfficialMall'),
        enterNumber:t('tab.enterNumber'),
        title:t('global.title'),
        male:t('global.male'),
        female:t('global.female'),
        correct:t('global.correct'),
        deny:t('global.deny'),
        companyProfile:t('home.companyProfile'),
        corporateCulture:t('home.corporateCulture'),
        qualificationsHonors:t('home.qualificationsHonors'),
        corporateCultureInfo:t('home.corporateCultureInfo'),
        brewing:t('home.brewing'),
        sauceAroma:t('home.sauceAroma'),
        Detail:t('home.Detail'),
        Consultation:t('home.Consultation'),
        ConsultationInfo:t('home.ConsultationInfo'),
        material:t('home.material'),
        water:t('home.water'),
        sorghum:t('home.sorghum'),
        wheat:t('home.wheat'),
        flavor:t('home.flavor'),
        maotaiFlavor:t('home.maotaiFlavor'),
        netContent:t('home.netContent'),
        alcoholContent:t('home.alcoholContent'),
        huakangLiquor:t('home.huakangLiquor'),
        recruit:t('service.recruit.recruit'),
        job:t('service.recruit.job'),
        workLocation:t('service.recruit.workLocation'),
        recruitingNumbers:t('service.recruit.recruitingNumbers'),
        operate:t('service.recruit.operate'),
        share:t('service.recruit.share'),
        deliver:t('service.recruit.deliver'),
        Resume:t('service.recruit.Resume'),
        rName:t('service.recruit.rName'),
        rGender:t('service.recruit.rGender'),
        rNation:t('service.recruit.rNation'),
        PositionApplied:t('service.recruit.PositionApplied'),
        SalaryExpectation:t('service.recruit.SalaryExpectation'),
        GraduationSchool:t('service.recruit.GraduationSchool'),
        OnCampusStudents:t('service.recruit.OnCampusStudents'),
        rContact:t('service.recruit.rContact'),
        rMailbox:t('service.recruit.rMailbox'),
        rMailAddress:t('service.recruit.rMailAddress'),
        PersonalSkills:t('service.recruit.PersonalSkills'),
        WorkExperience:t('service.recruit.WorkExperience'),
        SelfEvaluation:t('service.recruit.SelfEvaluation'),
        rSubmit:t('service.recruit.rSubmit'),
        contactUs:t('service.us.contactUs'),
        huakangIndustry:t('service.us.huakangIndustry'),
        serviceHotline:t('service.us.serviceHotline'),
        companyEmail:t('service.us.companyEmail'),
        companyAddress:t('service.us.companyAddress'),
        addressInfo:t('service.us.addressInfo'),
        advice:t('service.advice.advice'),
        name:t('service.advice.name'),
        enterName:t('service.advice.enterName'),
        gender:t('service.advice.gender'),
        gentleman:t('service.advice.gentleman'),
        lady:t('service.advice.lady'),
        telephone:t('service.advice.telephone'),
        PleaseNumber:t('service.advice.PleaseNumber'),
        Email:t('service.advice.Email'),
        enterEmail:t('service.advice.enterEmail'),
        messageContent:t('service.advice.messageContent'),
        enterMessage:t('service.advice.enterMessage'),
        confirmSubmission:t('service.advice.confirmSubmission'),
        hkInfo1:t('other.hkInfo1'),
        hkInfo2:t('other.hkInfo2'),
        EnterHuakang:t('other.EnterHuakang'),
        CorporateHonor:t('other.CorporateHonor'),
        organization:t('other.organization'),
        red:t('other.red'),
        orange:t('other.orange'),
        yellow:t('other.yellow'),
        green:t('other.green'),
        cyan:t('other.cyan'),
        blue:t('other.blue'),
        purple:t('other.purple'),
        golden:t('other.golden'),
        black:t('other.black'),
        hkProduct:t('other.hkProduct'),
        Age:t('service.recruit.Age'),
        details:t('h5Other.details'),
        YuanMonth:t('h5Other.YuanMonth'),
        hname:t('h5Other.hname'),
        hgender:t('h5Other.hgender'),
        information:t('h5Other.information'),
        address:t('h5Other.address'),
        message:t('h5Other.message'),
        mCompany:t('h5Other.mCompany'),
        mAddress:t('h5Other.mAddress'),
        mContact:t('h5Other.mContact'),
        mDeliver:t('h5Other.mDeliver'),
        counterfeiting: t('securityCheck.counterfeiting'),
        Confirm: t('securityCheck.Confirm'),
        info1: t('securityCheck.info1'),
        info2: t('securityCheck.info2'),
        info3: t('securityCheck.info3'),
        info4: t('securityCheck.info4'),
        Graphic:t('recruit.Graphic'),
        CityProvince:t('recruit.CityProvince'),
        Recruiting:t('recruit.Recruiting'),
        fullTime:t('recruit.fullTime'),
        responsibility:t('recruit.responsibility'),
        requirement:t('recruit.requirement'),
        zhenzhou:t('recruit.zhenzhou'),
        linfo1:t('recruit.linfo1'),
        linfo2:t('recruit.linfo2'),
        linfo3:t('recruit.linfo3'),
        linfo4:t('recruit.linfo4'),
        linfo5:t('recruit.linfo5'),
        linfo6:t('recruit.linfo6'),
        requirements:t('recruit.requirements'),
    }

}

