export default {
    global: {
        title: "Chinese Sons and Daughters · Happiness and Health",
        male: "Male",
        female: "Female",
        correct: "Yes",
        deny: "No",
    },
    tab: {
        home: "Home",
        enterprise: "Enterprise",
        product: "Product",
        mall: "mall",
        service: "Service",
        securityCheck: "Inquiry",
        contact: "contact",
        friendlyLinks: "Friendly Links",
        huaKangLiquorOfficialMall: "Hua Kang Liquor Official Mall",
        enterNumber: "Enter Anti-counterfeit Number",
    },
    home: {
        companyProfile: "Profile",
        corporateCulture: "Culture",
        qualificationsHonors: "QualificationsHonors",
        corporateCultureInfo: "Hua Kang Liquor is produced in Maotai Town, the Chinese liquor capital, located on the banks of Chishui River. It enjoys unique natural conditions and adopts traditional brewing techniques. Through nine steaming, eight fermentations, and seven liquor extractions, it is aged for over three years. It features prominent sauce aroma, elegance, mellow texture, long-lasting aftertaste, and lingering fragrance in an empty cup.",
        brewing: "Cellar Aging Brewing",
        sauceAroma: "A type of craft brewing sauce aroma",
        Detail: "Detail",
        Consultation: "Consultation",
        ConsultationInfo: "Depends on the Qing Dynasty cellar mud in Jin-Pen Basin, the cellar aroma provides a comfortable taste",
        material: "Ingredients",
        water: "Water",
        sorghum: "Sorghum",
        wheat: "Wheat",
        flavor: "Flavor Type",
        maotaiFlavor: "Sauce Aroma",
        netContent: "Net Content",
        alcoholContent: "Alcohol Content",
        huakangLiquor: "Hua Kang Liquor",
    },
    service: {
        recruit: {
            recruit: "Talent Recruitment",
            job: "Job Position",
            workLocation: "Work Location",
            recruitingNumbers: "Recruiting Numbers",
            operate: "Operate",
            share: "Share",
            deliver: "Submit",
            Resume: "Resume",
            rName: "Name",
            Age:"Age",
            rGender: "Gender",
            rNation: "Nationality",
            PositionApplied: "Position Applied",
            SalaryExpectation: "Expected Salary",
            GraduationSchool: "Graduation School",
            OnCampusStudents: "On Campus Students",
            rContact: "Contact",
            rMailbox: "Email",
            rMailAddress: "Mailing Address ∧∧",
            PersonalSkills: "Personal Skills",
            WorkExperience: "Work Experience",
            SelfEvaluation: "Self Evaluation",
            rSubmit: "Submit",
            male:"male",
            female:"female"
        },
        us: {
            contactUs: "Contact Us",
            huakangIndustry: "Huakang Liquor Industry Co., Ltd.",
            serviceHotline: "serviceHotline",
            companyEmail: "Company Email",
            companyAddress: "Company Address",
            addressInfo: "No. 33, Baolong Plaza, Agricultural East Road, Zhengdong New District, Zhengzhou City",
        },
        advice: {
            advice: "Consultation & Feedback",
            name: "Your Name",
            enterName: "Please enter your name",
            gender: "Your Gender",
            gentleman: "Mr.",
            lady: "Ms.",
            telephone: "Phone Number",
            PleaseNumber: "Please enter your phone number",
            Email: "Your Email",
            enterEmail: "Please enter your email",
            messageContent: "Message",
            enterMessage: "Please enter your message",
            confirmSubmission: "Confirm Submission",
        },
    },
    other:{
        hkInfo1:"Huakang Liquor Industry focuses on the research and development of cultural category collection Baijiu with the theme of \"happiness and well-being of Chinese children\". There is a folk proverb that goes, 'Without wine, there is no feast', and wine is one of the main beverages in human life.",
        hkInfo2:"Alcoholic drinks in China with a long history plays an important role in promoting the development of traditional history and culture. As a typical product of regional culture and national culture, cultural creative products play an important role in cultural propaganda and development by realizing creative product design of wine culture under the New normal of economy. ",
        EnterHuakang:"Enter Huakang",
        CorporateHonor:"Corporate honor",
        organization:"Exemplary organization in 2023",
        red:"Huakang Wine Red Classic",
        orange:"Huakang Wine orange Classic",
        yellow:"Huakang Wine yellow Classic",
        green:"Huakang Wine green Classic",
        cyan:"Huakang Wine cyan Classic",
        blue:"Huakang Wine blue Classic",
        purple:"Huakang Wine purple Classic",
        golden:"Huakang Wine golden Classic",
        black:"Huakang Wine black Classic",
        hkProduct:"Huakang products",

    },
    h5Other:{
        details:"details",
        YuanMonth:"Yuan/Month",
        hname:"Please enter your name",
        hgender:"Please select your gender",
        information:"Please enter your contact information",
        address:"Please enter your email address",
        message:"Please enter your message",
        mCompany:"Company",
        mAddress:"Address",
        mContact:"Contact",
        mDeliver:"Deliver",
    },
    securityCheck: {
        counterfeiting: "Please enter the anti-counterfeiting",
        Confirm: "Query",
        info1: "Confirmation query anti-counterfeiting query prompt: To ensure that consumers can objectively and fairly inquire about the authenticity of products, please self check and do not use manual services to check",
        info2:  "The anti-counterfeiting code query is correct - the product you purchased is a product that has passed through the anti-counterfeiting query center of Huakang Holdings. Please rest assured to use this product",
        info3: "Error in anti-counterfeiting code query - The anti-counterfeiting code query does not exist. The product you purchased has not been approved by the anti-counterfeiting query center of Huakang Holdings, and it does not exist. Beware of counterfeiting",
        info4: "Duplicate anti-counterfeiting code query - the same anti-counterfeiting code has been queried multiple times. Please confirm whether the first anti-counterfeiting code query was conducted by myself, not myself, and may be a counterfeit product",
    },
    recruit:{
        Graphic:"sale",
        CityProvince:"ZhengzhouHenan",
        Recruiting:"Recruiting 1 person",
        fullTime:"fullTime",
        requirements:"Job requirements",
        responsibility:"responsibility",
        requirement:"requirement",
        zhenzhou:"zhenzhou",
        linfo1:"1. Familiar with market conditions and have the ability to independently explore the market.",
        linfo2:"2. Strong expression ability, quick reaction, and love for learning.",
        linfo3:"3. Complete business related matters and actively develop new sales channels.",
        linfo4:"1. College degree or above in marketing related field, with at least one year of work experience in the same position preferred.",
        linfo5:"2. Having a good sense of service, patient, responsible, optimistic and generous personality, not afraid of hardship, able to bear certain work pressure.",
        linfo6:"3. Familiar with using computers and communication software, proficient in operating office software, with strong learning ability."
    }
}


